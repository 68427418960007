import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Link from 'next/link';
import HeaderWithCTA from '@components/header-with-cta';
import Footer from '@invideoio/web-shared/components/Footer';
import { StateContext } from '@store/store';
import AppLayout from '@layouts/AppLayout';

const Error404Page = () => {
  const globalState = useContext(StateContext);
  const { platform, os } = globalState;
  return (
    <AppLayout platform={platform}>
      <HeaderWithCTA />
      <div
        className={ivclass(
          'sm:iv-h-[530px]',
          'iv-h-[780px]',
          'iv-flex iv-flex-col iv-items-center iv-justify-center',
          'iv-mt-[65px]',
        )}
      >
        <div
          className={ivclass(
            'iv-text-center iv-rotate-180 ',
            'iv-tracking-[1px] iv-leading-[1.1] ',
            'iv-text-grey-100 iv-m-auto',
          )}
        >
          <p className={ivclass('iv-text-heading-5xl')}>404</p>
          <p className={ivclass('iv-text-heading-s')}>Looks like an error!</p>
        </div>
        <div className={ivclass('sm:iv-mb-[50px]', 'iv-mb-[150px]')}>
          <Link
            href="/"
            className={ivclass('!iv-text-grey-100')}
            passHref={true}
          >
            Visit the home page
          </Link>
        </div>
      </div>
      <Footer
        os={os}
        platform={platform}
        socialLinks={globalState.socialLinks}
      />
    </AppLayout>
  );
};

export default Error404Page;
